@import "base-colors.scss";

[data-namespace="RT15"] {
  .additional-services-container {
    text-align: center;
    padding: 10px 20px 20px;
    display: none;
    @media(max-width: 480px) {
      display: block;
    }
    h3 {
      font-size: 32px;
      line-height: 35px;
      span {
        display: block;
        margin: 8px auto;
        width: 60px;
        height: 3px;
        background: $base-accent-color;
      }
    }
    p {
      color: #787878;
      font-size: 15px;
      margin: 20px 0;
    }
  }
}
