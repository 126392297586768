@import "base-colors.scss";

[data-namespace="RT15"] {
  footer {
    background: #000000;
    color: #fff;
    text-align: center;
    padding: 60px 0;
    @media (max-width: 480px) {
      padding-bottom: 100px;
    }
    .container {
      padding: 0 20px;
    }
    a {
      color: #fff;
    }
    .logo-container {
      margin-bottom: 20px;
    }
    .copyright-container {
      font-size: 14px;
    }
    .phone-trigger {
      font-size: 20px;
      font-weight: 600;
    }
    .support-container {
      margin-top: 60px;
      @media(max-width: 480px){
        margin-top: 40px;
        font-size: 14px;
      }
      &.desktop {
        @media(max-width: 480px){
          display: none;
        }
      }
      &.mobile {
        display: none;
        @media(max-width: 480px){
          display: block;
        }
      }
    }
    .address-container {
      @media(max-width: 480px){
        font-size: 14px;
      }
    }
    .nav-bar {
      a {
        color: $base-accent-color;
        padding: 0 10px;
        &:first-of-type {
          border-right: 1px solid #fff;
        }
        @media(max-width: 480px){
          font-size: 12px;
        }
        @media(max-width: 375px){
          font-size: 14px;
          display: block;
          &:first-of-type {
            border-right: none;
          }
        }
      }
    }
  }
}
