@import "base-colors.scss";
@import "template.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
}

html, body {
    height: 100%;
    width: 100%;
}

body {
  height: 100%;
  width: 100%;
  font-weight: normal;
  line-height: 1.5;
  color: $base-font-color;
  -webkit-font-smoothing: antialiased;
  &.fixed {
    position: fixed;
    @media(max-width: 480px){
      position: static;
    }
  }
}

a, button {
    transition: all .25s ease;
    color: $default-anchor-color;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1200px;
  display: block;
  margin: 0 auto;
}
