@import "base-colors.scss";

[data-namespace="RT15"] {
  .modal-container {
    display: none;
    &.shown {
      display: block;
      @media(max-width: 480px){
        display: none !important; // Never show on mobile.
      }
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, .3);
      z-index: 100;
    }
    .modal {
      background: #fff;
      z-index: 101;
      padding: 40px 80px;
      text-align: center;
      position: fixed;
      width: 65%;
      top: calc(50vh - 300px);
      left: calc(17.5vw);
      @media(max-width: 480px) {
        width: 100%;
        left: 10px;
        top: 10px;
        bottom: 10px;
        right: 10px;
        width: auto;
        padding: 40px 20px;
      }
      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 33px;
        height: 33px;
        cursor: pointer;
        z-index: 102;
        @media(max-width: 480px) {
          height: 20px;
          width: 20px;
        }
        svg {
          path {
            fill: #323232;
          }
        }
      }
      p {
        font-size: 20px;
        font-weight: 600;
        color: #484848;
        @media(max-width: 768px) {
          font-size: 16px;
          margin-top: 40px;
        }
      }
      h1 {
        font-size: 42px;
        color: #323232;
        margin: 10px 0 30px;
        @media(max-width: 768px) {
          font-size: 26px;
        }
      }
      .icons-container {
        display: flex;
        justify-content: space-between;
        margin: 40px 0 20px;
        @media(max-width: 768px) {
          flex-wrap: wrap;
          margin: 40px 0;
        }
        .panel {
          margin: 0 10px;
          @media(max-width: 480px) {
            width: calc(50% - 40px);
            margin: 20px;
          }
          svg {
            width: 40px;
            height: auto;
            @media(max-width: 480px) {
              width: 80px;
            }
            @media(max-width: 480px) {
              width: 60px;
            }
          }
          aside {
            color: $base-accent-color;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            @media(max-width: 480px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
      .disclaimer {
        color: #737373;
        font-size: 12px;
        @media(max-width: 480px) {
          position: absolute;
          bottom: 20px;
          left: 20px;
          right: 20px;
        }
      }
    }
  }
}
