@import "base-colors.scss";

[data-namespace="RT15"] {
  .sticky-cta-container {
    position: fixed;
    bottom: -200px;
    left: 0;
    right: 0;
    display: none;
    z-index: 11;
    transition: all .25s ease;
    @media(max-width: 480px){
      display: block;
    }
    &.show {
      bottom: 0;
    }
    a {
      line-height: 70px;
      display: block;
      text-align: center;
      background: $base-accent-color;
      color: white;
      font-size: 24px;
      font-weight: 700;
    }
  }
}
