@import "base-colors.scss";

[data-namespace="RT15"] {
  .why-container {
    background: #323232;
    color: #fff;
    padding: 40px;
    @media(max-width: 480px){
      padding: 40px 10px;
    }
    h3 {
      color: $base-accent-color;
      text-align: left;
      font-size: 27px;
      @media(max-width: 480px){
        text-align: center;
        font-size: 32px;
      }
    }
    .panels-container {
      display: flex;
      justify-content: space-around;
      margin: 40px 0;
      @media(max-width: 480px){
        display: block;
        margin: 0;
      }
      .panel {
        padding: 20px 60px;
        border-right: 1px solid #fff;
        color: #fff;
        @media(max-width: 480px){
          padding: 40px 30px !important;
          text-align: center;
        }
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          border-right: none;
          padding-right: 0;
        }
        @media(max-width: 480px){
          border-right: none;
        }
        svg {
          width: 80px;
          height: auto;
          display: block;
          @media(max-width: 480px){
            margin: 0 auto;
          }
        }
        label {
          color: $base-accent-color;
          font-size: 20px;
          line-height: 29px;
          font-weight: 700;
          padding: 10px 0 0;
          display: block;
        }
        p {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }
}
