@import "base-colors.scss";

[data-namespace="RT15"] {
  .button-trigger {
    &.phone-trigger-container {
      font-family: "Work Sans", sans-serif !important;
      height: 50px;
      position: relative;
      text-align: left;
      padding: 3px 25px 3px 50px;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      img {
        position: absolute;
        left: 15px;
        top: 6px;
        width: 30px;
      }
      label {
        display: block;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
