@import "base-colors.scss";

[data-namespace="RT15"] {
  .how-can-we-help-panel-container {
    text-align: center;
    color: #fff;
    padding: 20px 0;
    h3 {
      font-size: 27px;
      line-height: 29px;
    }
    label {
      display: block;
      font-size: 20px;
      line-height: 24px;
    }
    a {
      font-size: 26px;
      font-weight: 700;
      color: #fff;
    }
  }
}
