@import "base-colors.scss";

[data-namespace="RT15"] {
  .price-guide-container {
    min-width: 350px;
    background: #fff;
    @media(max-width: 480px){
      min-width: 0;
    }
    ul {
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
      padding: 15px;
      list-style: none;
      @media(max-width: 768px){
        box-shadow: none;
        border: 1px solid #dfdfdf;
        padding: 0;
        border-bottom: none;
      }
      li {
        display: flex;
        justify-content: space-between;
        padding: 9px 20px;
        min-height: 40px;
        border-bottom: 1px solid #dfdfdf;
        font-size: 14px;
        &:hover, &:active, &:focus {
          background-color: #dfdfdf;
        }
        a {
          display: flex;
          justify-content: space-between;
          width: 100%;
          color: $base-font-color;
          label {
            cursor: pointer;
            flex: 3;
            @media(max-width: 480px){
              flex: 2;
            }
          }
          .category {
            flex: 3;
            @media(max-width: 480px){
              flex: 2;
            }
          }
          .price {
            text-align: right;
            flex: 2;
            @media(max-width: 480px){
              flex: 2;
            }
          }
        }
        &.header {
          height: auto;
          line-height: 17px;
          padding: 12px 20px;
          @media(max-width: 480px) {
            padding-left: 0;
          }
          &:hover, &:active, &:focus {
            background-color: transparent;
          }
          .icon-container {
            display: flex;
            justify-content: space-between;
            img {
              height: 42px;
              width: auto;
              margin-right: 10px;
              @media(max-width: 480px) {
                height: 30px;
              }
            }
            .content {
              font-size: 25px;
              color: #000;
              display: flex;
              justify-content: center;
              align-items: center;
              @media(max-width: 480px) {
                font-size: 18px;
              }
              strong {
                padding-left: 5px;
              }
            }
          }
          .title {
            text-align: center;
            span {
              display: block;
            }
          }
          @media(max-width: 768px) {
            border: 1px solid #fff;
            border-bottom: 1px solid #dfdfdf;
            position: relative;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
          }
        }
        &.disclaimer {
          display: none;
          height: auto;
          line-height: 17px;
          padding: 12px 20px;
          &:last-of-type {
            border-bottom: none;
          }
          &:hover, &:active, &:focus {
            background-color: transparent;
          }
        }
        &.desktop, &.mobile {
          &:hover, &:active, &:focus {
            background-color: transparent;
          }
        }
      }
      .icons-container {
        height: auto;
        line-height: 17px;
        padding: 12px 20px;
        display: flex;
        @media(max-width: 480px) {
          padding: 12px 0;
        }
        .icon-container {
          display: flex;
          justify-content: space-between;
          img {
            height: 32px;
            width: auto;
            margin-right: 10px;
          }
          .content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            strong {
              display: block;
              padding-left: 5px;
            }
          }
        }
      }
      .desktop {
        height: auto;
        &.wide {
          height: 45px;
          .icons-container {
            padding-top: 0;
          }
        }
        .icons-container {
          padding: 12px 0;
          justify-content: space-between;
          width: 100%;
          height: 55px;
          .content {
            font-size: 14px !important;
            align-items: flex-start !important;
          }
        }
        @media(max-width: 768px) {
          display: none;
        }
      }
      .mobile {
        display: none;
        border: 1px solid #fff;
        position: relative;
        bottom: -1px;
        left: -1px;
        top: 0;
        width: calc(100% + 2px);
        @media(max-width: 768px) {
          display: flex;
        }
      }
    }
  }
}
