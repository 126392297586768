@import "base-colors.scss";

[data-namespace="RT15"] {
  .hero-emergency-container {
    background: linear-gradient( rgba(44, 43, 91, .1), rgba(2, 2, 13, .35) );
    background-size: cover;
    position: relative;
    padding: 30px 20px;
    margin-bottom: 20px;
    @media(max-width: 480px) {
      padding-top: 40px;
    }
    .container {
      position: relative;
    }
    .logo-white-container {
      padding-top: 10px;
      img {
        cursor: pointer;
      }
      @media(max-width: 480px) {
        img {
          width: 100px;
        }
      }
    }
    .actions-container {
      position: absolute;
      top: 15px;
      right: 0;
      @media(max-width: 768px) {
        top: 0;
      }
      @media(max-width: 480px) {
        top: 15px;
      }
      .button-trigger {
        @media(max-width: 480px) {
          position: relative;
          top: -15px;
          height: 40px;
          padding: 3px 20px;
          &.phone-trigger-container {
            display: none;
          }
          .desktop {
            display: none;
          }
        }
      }
    }
    h1 {
      color: #fff;
      font-size: 40px;
      line-height: 54px;
      text-align: center;
      margin-top: 80px;
      @media(max-width: 768px) {
        margin-top: 20px;
      }
      @media(max-width: 480px) {
        font-size: 32px;
        line-height: 37px;
      }
    }
    h2 {
      color: #fff;
      font-size: 38px;
      line-height: 50px;
      font-weight: 400;
      text-align: center;
      @media(max-width: 480px) {
        font-size: 28px;
        line-height: 33px;
        margin-top: 10px;
      }
    }
    .buttons-container {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      @media(max-width: 768px) {
        display: block;
        margin-top: 20px;
      }
      .button-trigger {
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 8px 8px 8px 20px;
        font-weight: 700;
        font-size: 22px;
        @media(max-width: 1024px){
          font-size: 20px;
        }
        @media(max-width: 768px) {
          width: 70%;
          margin: 20px auto;
        }
        @media(max-width: 480px) {
          width: 100%;
          font-size: 18px;
          padding: 5px 8px;
        }
        @media(max-width: 320px) {
          font-size: 14px;
        }
        svg {
          width: 42px;
          height: auto;
          margin-right: 8px;
          path {
            fill: #fff;
          }
          @media(max-width: 320px) {
            width: 30px;
          }
        }
      }
    }
  }
}
