@import "base-colors.scss";

[data-namespace="RT15"] {
  .how-we-can-help-container {
    .container {
      padding: 40px 20px;
      @media(max-width: 480px) {
        padding: 20px 10px;
      }
    }
    h3 {
      font-size: 42px;
      margin: 20px 0;
      text-align: center;
      @media(max-width: 480px) {
        font-size: 32px;
        line-height: 35px;
      }
    }
    .lists-container {
      display: flex;
      justify-content: space-around;
      margin: 20px 100px;
      @media(max-width: 480px) {
        display: block;
        margin: 20px;
      }
      .list-container {
        width: 380px;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
        margin: 5px;
        text-align: center;
        list-style: none;
        @media(max-width: 480px) {
          width: 100%;
          margin: 40px 0;
        }
        li {
          font-size: 14px;
          line-height: 56px;
          border-bottom: 1px solid #DFDFDF;
          &:last-of-type {
            border-bottom: none;
          }
          &.header {
            background: $base-font-color;
            font-size: 27px;
            font-weight: 700;
            color: $base-accent-color;
            line-height: 65px;
          }
        }
      }
    }
    .disclaimer {
      color: rgb(150,150,150);
      font-size: 12px;
      margin: 20px auto;
      padding-bottom: 40px;
      text-align: center;
      font-weight: 500;
      padding: 0 20px;
      @media(max-width: 480px) {
        margin: 0 auto;
        width: 90%;
      }
      p {
        padding: 15px 0;
        &.copy {
          width: 70%;
          margin: 0 auto;
        }
      }
      hr {
        width: 30%;
        margin: 10px auto;
        opacity: .25;
      }
    }
  }
}
