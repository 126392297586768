@import "base-colors.scss";

[data-namespace="RT15"] {
  .about-container {
    text-align: center;
    padding: 40px;
    @media(max-width: 480px) {
      padding: 40px 20px;
    }
    h3 {
      font-size: 42px;
      @media(max-width: 480px) {
        font-size: 32px;
        line-height: 35px;
      }
      span {
        display: block;
        margin: 8px auto;
        width: 60px;
        height: 3px;
        background: $base-accent-color;
      }
    }
    p {
      color: #787878;
      font-size: 15px;
      margin: 20px 0;
    }
  }
}
