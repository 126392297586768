@import "base-colors.scss";

[data-namespace="RT15"] {
  .services-container {
    .container {
      padding: 20px;
      .panel {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        @media(max-width: 480px){
          display: block;
          padding: 20px 0;
        }
        .image-container {
          &.mobile {
            display: none;
          }
          @media(max-width: 768px){
            max-width: 35%;
            min-width: 35%;
          }
          @media(max-width: 480px){
            max-width: none;
            min-width: 0;
            &.mobile {
              display: block;
            }
            &.desktop {
              display: none;
            }
          }
          img {
            margin: 20px;
            @media(max-width: 768px){
              display: block;
              width: 100%;
            }
            @media(max-width: 480px){
              margin: 0 auto 10px;
              width: 80%;
            }
          }
        }
        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 0 30px;
          @media(max-width: 768px){
            max-width: 65%;
            min-width: 65%;
          }
          @media(max-width: 480px){
            max-width: none;
            min-width: 0;
            padding: 0;
          }
          h4 {
            text-align: left;
            width: 100%;
            text-transform: uppercase;
            font-size: 32px;
            letter-spacing: -.5px;
            margin-bottom: 10px;
            line-height: 36px;
            @media(max-width: 768px){
              font-size: 24px;
              line-height: 27px;
            }
          }
          p {
            color: #a1a1a1;
            font-size: 18px;
            line-height: 23px;
            @media(max-width: 480px){
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
      .disclaimer {
        color: rgb(150,150,150);
        font-size: 12px;
        margin: 20px auto;
        padding-bottom: 40px;
        text-align: center;
        font-weight: 500;
        padding: 0 20px;
        @media(max-width: 480px){
          padding: 0;
          text-align: left;
        }
        p {
          padding: 15px 0;
          &.copy {
            width: 70%;
            margin: 0 auto;
            @media(max-width: 480px){
              width: 100%;
            }
          }
        }
        hr {
          width: 30%;
          margin: 10px auto;
          opacity: .25;
        }
      }
    }
  }
}
