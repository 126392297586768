@import "base-colors.scss";

[data-namespace="RT15"] {
  header {
    padding: 10px 20px;
    background: #ffffff;
    height: 100px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    transition: top .25s ease;
    &.animate {
      top: -100px;
      &.visible {
        top: 0;
      }
    }
    @media(max-width: 480px) {
      height: 60px;
      padding: 10px;
    }
    .main-logo-container {
      display: inline-block;
      padding-top: 13px;
      @media(max-width: 480px) {
        padding-top: 5px;
      }
      img {
        width: 190px;
        @media(max-width: 480px) {
          width: 100px;
        }
      }
    }
    .actions-container {
      float: right;
      position: relative;
      top: 15px;
      .button-trigger {
        @media(max-width: 480px) {
          position: relative;
          top: -15px;
          height: 40px;
          padding: 3px 20px;
          &.phone-trigger-container {
            display: none;
          }
          .desktop {
            display: none;
          }
        }
      }
      .non-emergency-link {
        display: none;
        @media(max-width: 480px) {
          display: inline-block;
        }
      }
      .emergency-link {
        @media(max-width: 480px) {
          display: none;
        }
      }
      .override-show {
        display: inline-block !important;
      }
      .override-hide {
        display: none !important;
      }
    }
  }
}
