@import "base-colors.scss";

[data-namespace="RT15"] {
  .hero-services-container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
    .services-connect-container {
      padding: 30px 20px 0;
      width: 50%;
      @media(max-width: 768px) {
        width: 100%;
      }
      h3 {
        font-size: 38px;
        margin: 10px 0 30px;
        @media(max-width: 480px) {
          font-size: 32px;
          line-height: 35px;
        }
      }
      .panels-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media(max-width: 480px){
          display: block;
        }
        .panel {
          margin: 25px 10px;
          width: calc(50% - 10px);
          box-shadow: 0 0 7px 2px rgba(0, 0, 0, .1);
          position: relative;
          padding-bottom: 20px;
          display: block;
          &:nth-child(odd) {
            margin-left: 0;
          }
          &:nth-child(even) {
            margin-right: 0;
          }
          @media(max-width: 480px){
            width: 100%;
            margin: 20px 0 !important;
          }
          h4 {
            color: #fff;
            font-size: 27px;
            font-weight: 700;
            line-height: 29px;
            padding: 60px 0 15px 15px;
          }
          p {
            color: #787878;
            font-size: 15px;
            font-weight: 400;
            font-family: "Open Sans", sans-serif;
            padding: 15px;
          }
          span {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-weight: 600;
            font-size: 15px;
            transition: all .25s ease;
          }
          &:hover, &:active, &:focus {
            transform: scale(1.025);
            span {
              color: $base-cta-hover-color;
            }
          }
        }
      }
    }
    .price-guide-wrapper {
      background: #fff;
      position: absolute;
      bottom: 18px;
      right: 0;
      width: 50%;
      @media(max-width: 768px) {
        position: static;
        width: 100%;
        padding: 20px;
      }
      @media(max-width: 480px) {
        padding: 0 20px;
      }
    }
  }
}
