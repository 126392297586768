$default-anchor-color: #EF5C51;
$base-font-color: #42423E;
$base-accent-color: #19ACE3;
$base-cta-color: #EF5C51;
$base-cta-hover-color: #337AB6;

$accent-background: #323232;

@mixin delayedLoader {
  transition: opacity .25s ease;
  opacity: 1;
}
