@import "base-colors.scss";

[data-namespace="RT15"] {
  .hero-landing-container {
    background: linear-gradient( rgba(44, 43, 91, .1), rgba(2, 2, 13, .35) );
    background-size: cover;
    position: relative;
    padding: 30px 20px 0;
    @media(max-width: 480px) {
      padding-top: 40px;
    }
    .container {
      position: relative;
    }
    .logo-white-container {
      padding-top: 10px;
      @media(max-width: 480px) {
        img {
          width: 100px;
        }
      }
    }
    .actions-container {
      position: absolute;
      top: 15px;
      right: 0;
      @media(max-width: 768px) {
        top: 0;
      }
      @media(max-width: 480px) {
        top: 15px;
      }
      .button-trigger {
        @media(max-width: 480px) {
          position: relative;
          top: -15px;
          height: 40px;
          padding: 3px 20px;
          &.phone-trigger-container {
            display: none;
          }
          .desktop {
            display: none;
          }
        }
      }
    }
    h1 {
      color: #fff;
      font-size: 40px;
      line-height: 54px;
      width: 45%;
      margin: 80px 0 0 40px;
      @media(max-width: 768px) {
        width: 100%;
        margin:40px 0 0;
      }
      @media(max-width: 480px) {
        font-size: 32px;
        line-height: 37px;
      }
    }
    h2 {
      color: #fff;
      font-size: 38px;
      line-height: 50px;
      font-weight: 400;
      width: 50%;
      margin-left: 40px;
      padding-bottom: 30px;
      @media(max-width: 768px) {
        width: 100%;
        margin:40px 0 0;
      }
      @media(max-width: 480px) {
        font-size: 28px;
        line-height: 33px;
      }
    }
  }
}
